import { GroupDto, mergeCamelized, nonNullable } from "api-shared";
import { uniq } from "lodash";
import { renderPath } from "./tree";

export const getFullGroupPath = (groups: GroupDto[], currentGroup: GroupDto | null, skipSelf?: boolean): GroupDto[] => {
    const path: GroupDto[] = [];
    if (currentGroup === null) {
        return path;
    }
    let current: number | null = currentGroup.id;
    const check = (group: GroupDto) => group.id === current;
    while (current !== null) {
        const group = groups.find(check);
        if (group) {
            if (!skipSelf || (skipSelf && current !== currentGroup.id)) {
                path.unshift(group);
            }
            current = group.parentGroupId;
        } else {
            break;
        }
    }
    return path;
};

export const getChildGroups = (groups: GroupDto[], currentGroup: GroupDto | null): GroupDto[] => {
    const allChildren = [];
    const childGroupIds = currentGroup?.childGroupIds ?? groups.filter((group) => group.parentGroupId === null).map((group) => group.id);
    const currentChildren = childGroupIds.map((g) => groups.find((group) => group.id === g)).filter(nonNullable);
    for (const child of currentChildren) {
        allChildren.push(...getChildGroups(groups, child), child);
    }
    return allChildren;
};

export const getUserIdsFromGroupAndChildGroups = (groups: GroupDto[], groupId: number): number[] => {
    function findGroupById(id: number) {
        return groups.find((group) => group.id === id);
    }

    const group = findGroupById(groupId);
    if (group === undefined) {
        return [];
    }

    function findUserIds(group: GroupDto, userIds: number[]) {
        userIds.push(...group.userIds);
        const childGroups = group.childGroupIds.map(findGroupById).filter(nonNullable);
        childGroups.forEach((childGroup) => findUserIds(childGroup, userIds));
    }

    const userIds: number[] = [];
    findUserIds(group, userIds);

    return uniq(userIds);
};

export const fullGroupPath = (groups: GroupDto[], id: number, language: string): string => {
    const group = groups.find((g) => g.id === id);
    if (group === undefined) {
        return id.toString();
    }

    const path = getFullGroupPath(groups, group);
    return renderPath(path, mergeCamelized("name", language));
};
